.all-categories {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.all-categories h1 {
  font-size: 2.5rem;
  margin-bottom: 25px;
  color: #333;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 25px;
  max-width: 1200px;
  margin: 0 auto;
}

.category-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.category-icon {
  font-size: 2.5rem;
  margin-bottom: 12px;
  color: #00796b;
}

.category-card h3 {
  font-size: 1.3rem;
  margin-bottom: 8px;
  color: #333;
}

/* Mobile Optimierung */
@media (max-width: 768px) {
  .categories-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  .category-card {
    padding: 15px;
  }
}
