/* ✅ Unternehmensseite (ehemals BusinessAPage) */
.company-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(to bottom, #00796b, #004d40);
  color: white;
}

.company-page h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.company-page p {
  font-size: 1.2rem;
  max-width: 800px;
  margin-bottom: 30px;
  line-height: 1.6;
}

/* ✅ CTA-Buttons */
.company-cta {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.company-cta button {
  padding: 15px 25px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.btn-register {
  background: #ff9800;
  color: white;
}

.btn-register:hover {
  background: #e68900;
}

.btn-login {
  background: white;
  color: #00796b;
  border: 2px solid #00796b;
}

.btn-login:hover {
  background: #00796b;
  color: white;
}

/* ✅ Sektion: Unternehmensinformationen */
.company-details {
  margin-top: 50px;
  background: white;
  color: #333;
  padding: 40px;
  width: 100%;
  max-width: 900px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.company-details h2 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.company-details p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

/* ✅ Vorteile für Unternehmen */
.company-benefits {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.benefit-item {
  width: 300px;
  background: white;
  color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.benefit-item h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.benefit-item p {
  font-size: 1rem;
  color: #666;
}

/* ✅ Responsives Design */
@media (max-width: 900px) {
  .company-benefits {
    flex-direction: column;
    align-items: center;
  }

  .benefit-item {
    width: 90%;
  }

  .company-cta {
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }

  .company-details {
    padding: 30px;
    width: 90%;
  }
}
