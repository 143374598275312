/* Home-Container */
.home-container {
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    background-color: #f9f9f9;
}

/* Hero-Bereich */
.hero-section {
    margin-bottom: 40px;
}

/* Kategorien-Bereich */
.categories-section {
    margin: 40px 0;
}

/* Top-Bewertungen */
.top-rated-section {
    margin: 40px 0;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Karten-Bereich */
.map-section {
    margin: 40px 0;
}

/* Neueste Bewertungen */
.newest-reviews-section {
    margin-top: 40px;
    padding: 40px 20px;
    background-color: #f3f4f6;
}

/* Responsive Anpassungen */
@media (max-width: 900px) {
    .home-container {
        padding: 20px;
    }

    .top-rated-section {
        padding: 15px;
    }
}
