/* Grunddesign für die Kartenkomponente */
.map-container {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  background: #f0f0f0; /* Fallback-Farbe falls Karte nicht geladen wird */
}

/* Lade-Animation für die Map */
.map-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: bold;
  color: #00796b;
  text-align: center;
}

/* Responsivität */
@media (max-width: 768px) {
  .map-container {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .map-container {
    height: 250px;
  }
}
