/* ✅ Kundenbewertungsseite */
.new-review-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f3f4f6;
  padding: 20px;
}

/* ✅ Bewertungsformular */
.review-form {
  width: 100%;
  max-width: 500px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* ✅ Titel */
.review-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
  font-weight: bold;
}

/* ✅ Formular */
.review-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* ✅ Labels */
.review-form label {
  font-size: 1rem;
  color: #555;
  font-weight: bold;
}

/* ✅ Eingabefelder */
.review-form input,
.review-form textarea,
.review-form select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  transition: all 0.3s ease;
}

/* ✅ Fokus-Effekt */
.review-form input:focus,
.review-form textarea:focus,
.review-form select:focus {
  outline: none;
  border-color: #00796b;
  box-shadow: 0 0 5px rgba(0, 121, 107, 0.3);
}

/* ✅ Textarea */
.review-form textarea {
  min-height: 100px;
  resize: vertical;
}

/* ✅ Button */
.review-form button {
  padding: 14px;
  margin-top: 15px;
  border: none;
  border-radius: 5px;
  background-color: #00796b;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* ✅ Hover-Effekt für den Button */
.review-form button:hover {
  background-color: #004d40;
}

/* ✅ Fehlermeldung oder Erfolgsmeldung */
.review-form .message {
  text-align: center;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
}

.message.success {
  color: green;
}

.message.error {
  color: red;
}

/* ✅ Responsive Anpassungen */
@media (max-width: 600px) {
  .review-form {
    padding: 20px;
  }

  .review-form h2 {
    font-size: 1.8rem;
  }

  .review-form button {
    font-size: 1rem;
    padding: 12px;
  }
}
