.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #222;
  color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Logo-Styling */
.logo a {
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  color: #00bcd4;
  transition: color 0.3s;
}

.logo a:hover {
  color: #fff;
}

/* Navigation Menü */
.menu ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* Abstand zwischen den Menüpunkten */
.menu ul li {
  margin: 0 15px;
}

/* Links Styling */
.menu ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  padding: 10px 15px;
  transition: color 0.3s ease, background 0.3s ease;
  border-radius: 5px;
}

.menu ul li a:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #00bcd4;
}

/* Mobile Menü (Wird später als Toggle eingebaut) */
.menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.8rem;
}

/* Responsives Menü für kleinere Bildschirme */
@media (max-width: 768px) {
  .menu {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background: #222;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .menu ul {
      flex-direction: column;
      text-align: center;
      padding: 15px 0;
  }

  .menu ul li {
      margin: 10px 0;
  }

  .menu ul li a {
      display: block;
      padding: 12px 20px;
      font-size: 1.2rem;
  }

  .menu-toggle {
      display: block;
  }
}
