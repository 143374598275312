/* Container der Bewertungsform */
.review-form {
  width: 100%;
  max-width: 550px;
  margin: 40px auto;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.review-form:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Überschrift */
.review-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

/* Formular */
.review-form form {
  display: flex;
  flex-direction: column;
}

/* Labels */
.review-form label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  color: #444;
}

/* Eingabefelder */
.review-form input,
.review-form textarea,
.review-form select {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.review-form input:focus,
.review-form textarea:focus,
.review-form select:focus {
  border-color: #00796b;
  outline: none;
}

/* Bewertungs-Sterne */
.review-form .star-rating {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.review-form .star {
  font-size: 1.8rem;
  color: #ccc;
  cursor: pointer;
  transition: color 0.2s ease;
}

.review-form .star.filled {
  color: #ffc107;
}

/* Button */
.review-form button {
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #00796b;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 15px;
}

.review-form button:hover {
  background-color: #004d40;
  transform: scale(1.05);
}

/* Erfolgs- oder Fehlermeldung */
.message {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 6px;
  margin-top: 15px;
}

.message.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.message.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

/* Responsives Design */
@media (max-width: 600px) {
  .review-form {
    padding: 20px;
  }

  .review-form h2 {
    font-size: 1.5rem;
  }

  .review-form button {
    font-size: 1rem;
  }
}
