.categories {
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.categories h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

/* Grid für flexible Darstellung */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 20px;
  justify-content: center;
}

/* Karten-Styling */
.category-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Hover-Effekt */
.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Icon-Größe */
.category-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

/* Titel & Beschreibung */
.category-card h3 {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #222;
}

.category-card p {
  color: #666;
  font-size: 0.9rem;
  max-width: 90%;
}

/* Button-Styling */
.categories-button {
  margin-top: 30px;
}

.categories-button button {
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  background-color: #004d40;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

/* Hover- und Active-Effekte */
.categories-button button:hover {
  background-color: #00796b;
  transform: scale(1.05);
}

.categories-button button:active {
  transform: scale(0.98);
}

/* Responsive Anpassung */
@media (max-width: 600px) {
  .categories-grid {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .category-card {
      padding: 15px;
  }

  .category-icon {
      font-size: 2rem;
  }

  .categories-button button {
      width: 100%;
  }
}
