/* Grunddesign für Hero Section */
.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(135deg, #00bcd4, #00796b);
  color: #fff;
  height: 60vh;
  padding: 40px 20px;
  box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.1);
}

/* Hero Headline */
.hero-content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

/* Optimierte Suchleiste */
.search-bar {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
}

.search-bar input {
  width: 320px;
  padding: 12px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  transition: box-shadow 0.3s ease;
}

.search-bar input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 188, 212, 0.7);
}

/* Suchbutton */
.search-bar button {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  background: #004d40;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-bar button:hover {
  background: #00796b;
}

/* Verbesserte Suchergebnisse */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background: #fff;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
}

.search-results ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.search-results li {
  padding: 12px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 1rem;
  border-bottom: 1px solid #eee;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

/* Lade-Animation */
.loader {
  text-align: center;
  font-size: 1rem;
  color: #00796b;
  font-weight: bold;
}

/* CTA-Buttons */
.cta-buttons {
  margin-top: 30px;
  display: flex;
  gap: 15px;
}

.cta-buttons button {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.cta-buttons button:first-child {
  background: #004d40;
  color: #fff;
}

.cta-buttons button:first-child:hover {
  background: #00796b;
}

.cta-buttons button:last-child {
  background: #00796b;
  color: #fff;
}

.cta-buttons button:last-child:hover {
  background: #004d40;
}

/* Responsivität */
@media (max-width: 768px) {
  .hero {
    height: auto;
    padding: 60px 20px;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .search-bar input {
    width: 100%;
  }

  .cta-buttons {
    flex-direction: column;
    width: 100%;
  }

  .cta-buttons button {
    width: 100%;
  }
}
