.newest-reviews {
  text-align: center;
  padding: 40px 20px;
  background-color: #f3f4f6;
}

.newest-reviews h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.reviews-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.review-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.review-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.review-card p {
  font-size: 1rem;
  color: #555;
}

.review-meta {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #666;
}

.reviews-button {
  margin-top: 30px;
}

.reviews-button button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #00796b;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reviews-button button:hover {
  background-color: #004d40;
}
