.top-rated {
  text-align: center;
  padding: 50px 20px;
  background-color: #f3f4f6;
}

.top-rated h2 {
  font-size: 2.2rem;
  margin-bottom: 25px;
  color: #333;
}

.top-rated-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Damit die Karten sich bei kleineren Bildschirmen umbrechen */
}

.top-rated-card {
  background: #fff;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 280px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.top-rated-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.top-rated-card h3 {
  font-size: 1.3rem;
  margin-bottom: 12px;
  color: #00796b;
}

.top-rated-card p {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

/* Sterne-Bewertung */
.star-rating {
  font-size: 1.2rem;
  color: #f4b400;
}

/* Mobile Optimierung */
@media (max-width: 768px) {
  .top-rated-list {
    flex-direction: column;
    align-items: center;
  }

  .top-rated-card {
    width: 90%;
    max-width: 320px;
  }
}
