/* Login-Container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f4f6;
}

/* Login-Box */
.login-box {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Titel */
.login-box h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #333;
}

/* Formular */
.login-form {
    display: flex;
    flex-direction: column;
}

/* Eingabefelder */
.login-form input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
}

/* Fokus-Effekt für Inputs */
.login-form input:focus {
    outline: none;
    border-color: #00796b;
    box-shadow: 0 0 3px rgba(0, 121, 107, 0.5);
}

/* Login-Button */
.login-button {
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    background-color: #00796b;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover-Effekt für den Button */
.login-button:hover {
    background-color: #004d40;
}

/* Fehlermeldungen */
.login-error {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

/* Link zur Registrierung */
.register-link {
    margin-top: 15px;
    font-size: 0.9rem;
    color: #00796b;
}

.register-link a {
    color: #00796b;
    text-decoration: none;
    font-weight: bold;
}

.register-link a:hover {
    text-decoration: underline;
}
