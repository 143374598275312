/* Hauptcontainer */
.search-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

/* Suchleiste */
.search-bar {
  position: relative;
  z-index: 11;
}

.search-bar input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-bar input:focus {
  border-color: #00796b;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 121, 107, 0.3);
}

/* Overlay für den Hintergrund */
.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  backdrop-filter: blur(3px);
}

/* Suchergebnis-Container */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  z-index: 12;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

/* Einzelne Ergebnis-Items */
.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.result-item:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.result-item:hover {
  background-color: #f5f5f5;
}

/* Linke Seite des Ergebnisses */
.result-left {
  display: flex;
  align-items: center;
}

.result-logo img {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  margin-right: 12px;
}

.result-info {
  text-align: left;
}

.result-name {
  font-weight: bold;
  font-size: 1rem;
}

.result-details {
  font-size: 0.9rem;
  color: #555;
}

.result-details a {
  color: #00796b;
  text-decoration: none;
}

.result-details a:hover {
  text-decoration: underline;
}

/* Bewertung */
.result-rating .rating-badge {
  display: inline-block;
  background: #00796b;
  color: #fff;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: bold;
  min-width: 40px;
  text-align: center;
}

/* Mobile Optimierung */
@media (max-width: 600px) {
  .search-container {
    max-width: 100%;
  }

  .search-results {
    max-height: 250px;
  }

  .result-logo img {
    width: 40px;
    height: 40px;
  }

  .result-name {
    font-size: 0.95rem;
  }

  .result-rating .rating-badge {
    padding: 5px 10px;
    min-width: 30px;
  }
}
